import React from "react";
import Layout from "../components/Layout";
import QuickActions from "../components/QuickActions";
import { graphql } from "gatsby";
import "./prestation.sass";
import "../Styles.sass";
import { Link } from "gatsby";
import { checkoutFactory, getContract } from "../helpers";
import arrow from "../images/arrow_up.png";
import DocuList from "../components/DocuList";

export default function Prestation({ data, pageContext }) {
  const domain = pageContext.prestationDomain;
  const prestationSlug = pageContext.prestationSlug;
  const prestationContractID = pageContext.prestationContractID;
  const prestationCheckout = pageContext.prestationCheckout;
  // why not use pageContext here as well?
  const prestation = data.allDomainsJson.edges[0].node.prestations.filter(
    (p) => p.slug === prestationSlug
  )[0];

  console.log("----------------------_>");
  console.log(prestation.metaDescription);

  const devisCTA = () => {
    return (
      <div>
        <div id="prestation-cta-holder">
          <Link
            to="/checkout"
            state={checkoutFactory(
              "devis",
              "-",
              prestation.title,
              domain,
              prestation.subTitle,
              prestation.checkboxes
            )}
          >
            {prestation.cta} <img src={arrow} alt="arrow icon"></img>
          </Link>
        </div>
      </div>
    );
  };

  const generalCTA = () => {
    return (
      <div>
        <div id="prestation-cta-holder">
          <Link
            to="/checkout"
            state={checkoutFactory(
              "meeting",
              "-",
              prestation.title,
              domain,
              prestation.subTitle,
              prestation.checkboxes
            )}
          >
            {prestation.cta} <img src={arrow} alt="arrow icon"></img>
          </Link>
        </div>
      </div>
    );
  };

  // helper that converts title to price
  const getAboPrice = (title) => {
    if (title.includes("Basic")) {
      return 500;
    }
    if (title.includes("Standard")) {
      return 1000;
    }
    if (title.includes("Premium")) {
      return 1500;
    }
  };

  const aboCTA = () => {
    return (
      <div>
        <div id="prestation-cta-holder">
          <Link
            to="/checkout"
            state={checkoutFactory(
              "abo",
              getAboPrice(prestation.title),
              prestation.title,
              domain,
              prestation.subTitle,
              prestation.checkboxes
            )}
          >
            {prestation.cta} <img src={arrow} alt="arrow icon"></img>
          </Link>
        </div>
      </div>
    );
  };

  const societeCTA = () => {
    return (
      <div>
        <div id="prestation-cta-holder">
          <Link
            to="/checkout"
            state={checkoutFactory(
              "societe",
              "-",
              prestation.title,
              domain,
              prestation.subTitle,
              prestation.checkboxes
            )}
          >
            {prestation.cta} <img src={arrow} alt="arrow icon"></img>
          </Link>
        </div>
      </div>
    );
  };

  // TODO need to replace using GRAPHQL
  const contractCTA = () => {
    // using the attached contractID of the Domains object, find the matching contract to pull price
    const contract = getContract(prestationContractID);
    // note: could decide to use the info form the Contact list instead of that of the Domain.json
    return (
      <div>
        <div id="prestation-cta-holder">
          <Link
            to="/checkout"
            state={checkoutFactory(
              "contract",
              contract.price,
              prestation.title,
              domain,
              prestation.subTitle,
              prestation.checkboxes,
              prestationContractID
            )}
          >
            {prestation.cta} <img src={arrow} alt="arrow-icon"></img>
          </Link>
        </div>
      </div>
    );
  };

  const docusCTA = () => {
    return (
      <DocuList
        documents={data.allDocumentsJson.edges}
        filter={prestation.docuFilter}
      ></DocuList>
    );
  };

  const getCallToAction = () => {
    if (prestation.cta.length < 1) {
      return "";
    }
    // general scenario
    if (prestationCheckout === "general") {
      return generalCTA();
    }

    if (prestationCheckout === "abo") {
      return aboCTA();
    }

    if (prestationCheckout === "societe") {
      return societeCTA();
    }

    if (prestationCheckout === "contract") {
      return contractCTA();
    }

    if (prestationCheckout === "docus") {
      return docusCTA();
    }

    if (prestationCheckout === "devis") {
      return devisCTA();
    }
  };

  return (
    <Layout title={prestation.title} description={prestation.metaDescription}>
      <div id="header-box">
        <div id="left" className="prestation-column">
          <h5>{domain}</h5>
          <h2>{prestation.title}</h2>
          <p>{prestation.subTitle}</p>
        </div>
        <div id="right" className="prestation-column">
          <ul>
            {prestation.checkboxes.map((checkbox) => (
              <li>{checkbox}</li>
            ))}
            <li id="price-bullet">
              {prestation.price === "En savoir plus" ? "" : prestation.price}
            </li>
          </ul>
        </div>
      </div>
      <div id="prestation-description">
      {getCallToAction()}
        <p>
          {prestation.description.split(" ").map((w) => {
            if (w.split("---").length > 1) {
              return (
                <a
                  className="description-link"
                  target="_blank"
                  href={w.split("---")[1]}
                  rel="noreferrer"
                >
                  (lien){" "}
                </a>
              );
            } else {
              return w + " ";
            }
          })}
        </p>
        
      </div>
      <div id="prestation-actions">
        {domain === "Abonnements juridiques" ? (
          ""
        ) : (
          <QuickActions></QuickActions>
        )}
      </div>
    </Layout>
  );
}

// Must be adapted to load contracts not from helper but from graphql
// TODO: is this needed????
// we query for the node with the matching slug

export const query = graphql`
  query ($prestationSlug: String!) {
    allDomainsJson(
      filter: { prestations: { elemMatch: { slug: { eq: $prestationSlug } } } }
    ) {
      edges {
        node {
          id
          prestations {
            title
            subTitle
            slug
            price
            description
            metaDescription
            ctaLink
            cta
            checkboxes
            cardTitle
            cardText
            cardPrice
            contractID
            docuFilter
          }
        }
      }
    }
    allDocumentsJson {
      edges {
        node {
          slug
          title
          price
          tag
        }
      }
    }
  }
`;
