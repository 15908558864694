import * as React from "react";
import "./DocuList.sass";
import { Link } from "gatsby";
import docus from "../images/icons/docus.png";

const DocuList = (props) => {
  return (
    <div id="docu-holder">
      {props.documents
        .filter((doc) => {
          // for specified filter, otherwise use all
          if (props.filter !== "all") {
            if (
              doc.node.tag != undefined &&
              doc.node.tag.includes(props.filter)
            ) {
              return true;
            } else {
              return false;
            }
          } else {
            return true;
          }
        })
        .sort((a, b) => {
          return a.node.title.localeCompare(b.node.title);
        })
        .map((c) => {
          return (
            <Link
              id="docu-link"
              to={"/domaines/vos-contrats/contrats-et-documents/" + c.node.slug}
            >
              <img src={docus}></img>
              <h4>{c.node.title}</h4>
              <h3>
                {c.node.price.includes("Gratuit")
                  ? c.node.price
                  : "CHF " + c.node.price + ".-"}
              </h3>
            </Link>
          );
        })}
    </div>
  );
};

export default DocuList;
